import * as CONS from '@/constants';

export default {
  name: 'headline',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 2,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      CONS,
    };
  },
  computed: {
    tagName () {
      if (this.level < 1 || this.level > 7) {
        return 'div';
      }
      return `h${this.level}`;
    },
    parsedTitle () {
      return (this.title || '').replace(/\\n/g, '<br class="mobile-only">');
    },
  },
  methods: {},
};
