import * as CONS from '@/constants';

export default {
  name: 'doctor-member-list',
  components: {},
  props: [],
  data () {
    return {
      CONS,
      doctors: [
        {
          photo: 'doctor_maruyama.jpg',
          width: 1208,
          height: 808,
          name: '丸山 浩司',
          message: '救急車を多数受け入れる病院で勤務してきた中で、患者さん自身が適切なタイミングで救急受診をするのは難しいことに問題意識をもっていました。現代の医療の課題に向き合い、日本の新しい医療インフラを提供できるように精進してまいります。',
        },
        {
          photo: 'doctor_kazama.jpg',
          width: 1208,
          height: 808,
          name: '風間 尚子',
          message: '発熱などの急性疾患は夜間に発症することも多く、不安な夜を過ごされるご家族も多いと思います。小さな不安でもお伺いすることで大きな安心感に変えていきたいという思いから、コールドクターに参画しました。より多くの方々にサービスを提供できるよう努めてまいります。',
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
